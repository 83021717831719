import { gql, useQuery } from '@apollo/client'

import Session from '../modules/Session'

import { SessionPayload } from '../types'

const query = gql`
  query FetchSession {
    session {
      cartCount
      featureFlags {
        showRfqBanner
        googleSignIn
      }
      user {
        id
        name
        email
        role
        phoneNumber
        store {
          id
          name
          onboardedSuppDashDatetime
          shipsFree
          gearflowShipping
        }
        organization {
          id
          buyerDashboardAccess
        }
      }
    }
  }
`

const useFetchSession = () => {
  const result = useQuery<{ session: SessionPayload }>(query)

  const data = result.data && {
    ...result.data,
    session: Session.fromPayload(result.data.session),
  }

  return { ...result, data }
}

export default useFetchSession
