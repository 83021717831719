import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type TertiaryProps = Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick' | 'type' | 'disabled' | 'children' | 'className' | 'form'
>

type ButtonProps = TertiaryProps & {
  size?: 'sm' | 'md' | 'lg'
  performing?: boolean
  performingClassName?: string
}

type PrimaryProps = ButtonProps & {
  color?: 'blue' | 'orange'
}

const buttonClasses = {
  base: 'inline-block border font-normal rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
  primary: (color: PrimaryProps['color'], disabled = false) =>
    classNames('text-white border-transparent', {
      'bg-gearflow': color === 'orange',
      'bg-blue-600': color === 'blue',
      'hover:bg-yellow-700': color === 'orange' && !disabled,
      'hover:bg-blue-700': color === 'blue' && !disabled,
      'focus:ring-yellow-500': color === 'orange',
      'focus:ring-blue-500': color === 'blue',
    }),
  secondary: (disabled = false) =>
    classNames('text-gray-700 border-gray-300 bg-white focus:ring-yellow-500', {
      'hover:bg-gray-50': !disabled,
    }),
  sm: 'px-2.5 py-1.5 text-xs',
  md: 'px-4 py-2 text-sm',
  lg: 'px-6 py-3 text-base',
}

const spinnerClasses = {
  secondary:
    'animate-spin-slow border-t-white inline-block rounded-full border-2 border-gray-700 h-3 w-3',
  primary: (color: PrimaryProps['color']) =>
    classNames(
      'animate-spin-slow inline-block rounded-full border-2 borderw-white h-3 w-3',
      color === 'blue' ? 'border-t-blue-600' : 'border-t-gearflow'
    ),
}

const Content = ({
  performing,
  children,
  className,
}: {
  performing: boolean
  children: React.ReactNode
  className?: string
}) =>
  performing ? (
    <div className="flex items-center gap-2 justify-center">
      <span className={className} />
      <span>{children}</span>
    </div>
  ) : (
    <>{children}</>
  )

const Tertiary = forwardRef<HTMLButtonElement, TertiaryProps>(
  ({ children, disabled, type = 'button', className, ...props }, ref) => (
    <button
      {...props}
      type={type}
      className={twMerge(
        'underline underline-offset-2',
        disabled ? 'text-gray-300' : 'text-blue-500',
        className
      )}
      disabled={disabled}
      ref={ref}
    >
      {children}
    </button>
  )
)

const Secondary = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled,
      type = 'button',
      performing = false,
      size = 'md',
      className,
      performingClassName,
      ...props
    },
    ref
  ) => (
    <button
      {...props}
      type={type}
      className={twMerge(
        `${buttonClasses.base} ${buttonClasses.secondary(performing || disabled)} ${
          buttonClasses[size]
        }`,
        className
      )}
      disabled={performing || disabled}
      ref={ref}
    >
      <Content
        performing={performing}
        className={classNames(spinnerClasses.secondary, performingClassName)}
      >
        {children}
      </Content>
    </button>
  )
)

const Primary = forwardRef<HTMLButtonElement, PrimaryProps>(
  (
    {
      children,
      disabled,
      type = 'button',
      performing = false,
      size = 'md',
      className,
      color = 'orange',
      performingClassName,
      ...props
    },
    ref
  ) => (
    <button
      {...props}
      type={type}
      className={twMerge(
        `${buttonClasses.base} ${buttonClasses.primary(color, performing || disabled)} ${
          buttonClasses[size]
        }`,
        className
      )}
      disabled={performing || disabled}
      ref={ref}
    >
      <Content
        performing={performing}
        className={classNames(spinnerClasses.primary(color), performingClassName)}
      >
        {children}
      </Content>
    </button>
  )
)

export default { T: Tertiary, S: Secondary, P: Primary }
