import { Link, useLocation } from 'react-router-dom'

import useConfig from '@/gf/hooks/useConfig'

import Spinner from '@/gf/components/Spinner'
import ProductM from '@/gf/modules/Product'
import Card from './Card'
import RFQBanner from './Layout/RFQBanner'
import OemBadge from './OemBadge'
import SalePriceRange from './SalePriceRange'
import SourceProductInfo from './SourceProductInfo'
import Truck from './svgs/Truck'

import { type SourceProduct } from '../types'

const path = (sourceProduct: SourceProduct) => {
  if (sourceProduct.products.length === 1) return `/itm/${sourceProduct.products[0].slugPath}`
  return `/item/${sourceProduct.slugPath}`
}

const SourceProduct = ({ sourceProduct }: { sourceProduct: SourceProduct }) => {
  const config = useConfig()
  const location = useLocation()

  return (
    <Card className="sm:grid grid-cols-8 gap-6">
      <div className="col-span-2">
        <Link to={path(sourceProduct)}>
          <img alt={sourceProduct.name} src={ProductM.primaryImageURL(sourceProduct, 'card')} />
        </Link>
      </div>

      <div className="col-span-4 sm:border-r pr-6">
        <div className="flex gap-2 items-center">
          <h2 className="text-lg font-bold text-gearflow no-underline hover:underline">
            <Link to={path(sourceProduct)}>{sourceProduct.name}</Link>
          </h2>
          {sourceProduct.isOem && <OemBadge />}
        </div>

        <SourceProductInfo sourceProduct={sourceProduct} displayStores />
      </div>

      <div className="col-span-2 flex flex-col justify-between gap-2 mt-6 sm:mt-0">
        <div className="space-y-2">
          <div className="text-xl font-bold text-green-600">
            <SalePriceRange products={sourceProduct.products} />
          </div>

          {sourceProduct.availability === 'backordered' && (
            <span className="inline-flex items-center px-3 py-0.5 rounded-md text-sm leading-5 bg-red-50 text-red-700">
              <span className="h-5 w-5 mr-1">
                <Truck />
              </span>{' '}
              Backordered
            </span>
          )}
        </div>

        <div className="space-y-2">
          {sourceProduct.products.length === 1 && (
            <form action="/cart" method="post">
              <input name="_csrf_token" type="hidden" value={config.csrfToken} />
              <input
                id="cart_product"
                name="cart[product]"
                type="hidden"
                value={sourceProduct.products[0].id}
              />
              <input
                id="cart_redirect_to"
                name="cart[redirect_to]"
                type="hidden"
                value={`${location.pathname}${location.search}`}
              />
              <input id="cart_quantity" name="cart[quantity]" type="hidden" value="1" />

              {sourceProduct.availability === 'out_of_stock' ? (
                <button
                  type="button"
                  className="block w-full px-5 py-3 border border-transparent leading-6 rounded-md text-white bg-gray-400 focus:outline-none focus:ring transition duration-150 ease-in-out cursor-not-allowed"
                  disabled
                >
                  Out of Stock
                </button>
              ) : (
                <button
                  className="block w-full px-5 py-3 border border-transparent leading-6 rounded-md text-white bg-gearflow hover:bg-orange-700 focus:outline-none focus:ring transition duration-150 ease-in-out"
                  type="submit"
                >
                  Add to Cart
                </button>
              )}
            </form>
          )}
        </div>
      </div>
    </Card>
  )
}

const SourceProducts = ({ sourceProducts }: { sourceProducts?: SourceProduct[] }) => {
  if (!sourceProducts) return <Spinner />

  return (
    <>
      {sourceProducts.length ? (
        <div className="space-y-4">
          {sourceProducts.map((sp) => (
            <SourceProduct sourceProduct={sp} key={sp.id} />
          ))}
        </div>
      ) : (
        <div className="max-w-3xl mx-auto">
          <p className="text-center my-8 text-xl">Sorry, no results found.</p>
          <RFQBanner />
        </div>
      )}
    </>
  )
}
export default SourceProducts
