import { Helmet } from 'react-helmet'
import useConfig from '@/gf/hooks/useConfig'
import Header from './LayoutNext/Header'
import Footer from './LayoutNext/Footer'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const config = useConfig()
  return (
    <>
      <Helmet>
        <title>Construction Equipment Parts and Attachments | Gearflow</title>
        <meta name="keywords" content="equipment, marketplace, parts, construction" />
        <meta
          name="description"
          content="Gearflow simplifies parts ordering for heavy equipment fleets. Get better parts access, communication, and reporting all in one platform. Sign up free!"
        />
        <link rel="canonical" href={`${config.gfBaseUrl}/`} />
        <style type="text/css">
          {`
            html {
              font-family: 'IBM Plex Sans', 'Lato', 'Helvetica', 'Arial', 'Inter', 'sans-serif';
            }
          `}
        </style>
      </Helmet>
      <div className="relative">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
