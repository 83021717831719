import { Helmet } from 'react-helmet'

import useConfig from '@/gf/hooks/useConfig'
import Layout from '../components/LayoutNext'
import AboutTop from './About/AboutTop'
import AboutMiddle from './About/AboutMiddle'
import AboutBottom from './About/AboutBottom'

const About = () => {
  const config = useConfig()

  return (
    <>
      <Helmet>
        <title>Construction Equipment Parts and Attachments | Gearflow</title>
        <meta name="keywords" content="equipment, marketplace, parts, construction" />
        <meta
          name="description"
          content="Gearflow simplifies parts ordering for heavy equipment fleets. Get better parts access, communication, and reporting all in one platform. Sign up free!"
        />
        <link rel="canonical" href={`${config.gfBaseUrl}/`} />
      </Helmet>
      <Layout>
        <AboutTop />
        <AboutMiddle />
        <AboutBottom />
      </Layout>
    </>
  )
}

export default About
