import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import nth from 'lodash/nth'

import ProductM from '@/gf/modules/Product'
import useConfig from '@/gf/hooks/useConfig'
import CategoryTree from '../modules/CategoryTree'
import useCategories from '../hooks/useCategories'
import useSearchSourceProducts from '../hooks/useSearchSourceProducts'

import RightArrow from '../components/svgs/RightArrow'
import AddToCart from '../components/SourceProductAddToCart'
import RelatedProducts from '../components/RelatedProducts'
import Section from '../components/Section'
import Layout from '../components/Layout'
import Container from '../components/Layout/Container'
import CanonicalRedirect from '../components/CanonicalRedirect'

const Crumb = ({ category }: { category: { slugPath: string; name: string } }) => (
  <>
    <Link to={`/c/${category.slugPath}`}>{category.name}</Link>
    <RightArrow />
  </>
)

const SourceProduct = () => {
  const config = useConfig()
  const slugPath = (useParams<{ ['*']: string }>() as { slugPath: string })['*']
  const categories = useCategories()

  const sourceProducts = useSearchSourceProducts({ variables: { slugPath } }).data
    ?.sourceProductsSearch.sourceProducts

  const sourceProduct = sourceProducts && (nth(sourceProducts, 0) || null)

  const category = React.useMemo(
    () =>
      sourceProduct &&
      categories &&
      sourceProduct.category &&
      CategoryTree.findById(categories, sourceProduct.category.id),
    [!categories, sourceProduct?.id]
  )

  if (sourceProduct === null) {
    return (
      <Layout>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <section className="grid grid-cols-4 gap-4 px-4 sm:px-0 z-0 pb-4">
          <section className="col-span-4">
            <div className="max-w-3xl mx-auto">
              <div className="p-16 text-center">
                <h1 className="text-2xl">Sorry, the page you are looking for does not exist.</h1>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
  }

  return (
    <Layout showRfq>
      {sourceProduct && (
        <Helmet>
          <title>{sourceProduct.name} | Gearflow</title>
          <meta name="description" content={sourceProduct.shortDescription || sourceProduct.name} />
          {(!sourceProduct.longDescription || sourceProduct.longDescription.length === 0) && (
            <meta name="robots" content="noindex" />
          )}
          <link rel="canonical" href={`${config.gfBaseUrl}/item/${sourceProduct.slugPath}`} />
        </Helmet>
      )}

      {sourceProduct && <CanonicalRedirect path={`/item/${sourceProduct.slugPath}`} />}

      <Container className="py-4">
        {category && (
          <div className="flex mb-4">
            {category.ascendants.map((c) => (
              <Crumb category={c} key={c.id} />
            ))}
            <Crumb category={category} />
            {sourceProduct?.name}
          </div>
        )}

        <div className="grid grid-cols-1 gap-4">
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 auto-cols-max">
            <Section className="lg:col-span-3">
              {sourceProduct && (
                <img
                  src={ProductM.primaryImageURL(sourceProduct, 'main')}
                  alt={sourceProduct.name}
                  className="mx-auto"
                  height="360"
                  width="560"
                />
              )}
            </Section>

            <div className="lg:col-span-2 h-full">
              <div className="grid grid-cols-1 gap-y-4 h-full">
                <AddToCart sourceProduct={sourceProduct} />
              </div>
            </div>
          </div>

          <Section title="Product Description">
            {sourceProduct && (
              <div dangerouslySetInnerHTML={{ __html: sourceProduct.longDescription }} />
            )}
          </Section>

          <RelatedProducts product={sourceProduct} category={category} />
        </div>
      </Container>
    </Layout>
  )
}

export default SourceProduct
