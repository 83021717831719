import Header from './Layout/Header'
import Footer from './Layout/Footer'
import RFQBanner from './Layout/RFQBanner'
import RFQModal from './SubmitRFQ/Modal'
import RFQComplete from './SubmitRFQ/Complete'
import useSession from '../hooks/useFetchSession'

import useToggle from '@/gf/hooks/useToggle'

const Layout = ({
  children,
  showRfq = false,
  searchQuery,
}: {
  children: React.ReactNode
  showRfq?: boolean
  searchQuery?: string
}) => {
  const { data } = useSession()
  const [RFQBannerShown, { off: closeRFQBanner }] = useToggle(showRfq)

  return (
    <>
      <RFQModal />
      <RFQComplete />

      <Header searchQuery={searchQuery} />
      {data?.session?.featureFlags?.showRfqBanner && RFQBannerShown && (
        <RFQBanner onClose={closeRFQBanner} />
      )}
      <main className="bg-gray-50">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
