import {
  Address as AddressT,
  RequestForQuoteStep as BuyersRequestForQuoteStep,
  Conversation as ConversationT,
  CreditLimit,
  DeliveryMethod,
  Message as MessageT,
  Order as OrderT,
  OrgMachine as OrgMachineT,
  Organization as OrganizationT,
  PreferredSetting,
  RequestForQuote as RequestForQuoteT,
  ShippingOptions,
  PaymentMethod as StoreOrderPaymentMethod,
  StoreOrderProblem,
  StoreOrderProblemType,
  StoreOrderState,
  StoreOrder as StoreOrderT,
  Store as StoreT,
  User as UserT,
} from '@/buyers/_gen/gql'
import {
  RequestForQuoteStep as DealersRequestForQuoteStep,
  StoreOrderStep as DealersStoreOrderStep,
} from '@/dealers/_gen/gql'
import { ApolloError } from '@apollo/client'
import { ValueFormat } from '@nivo/core'
import { DatumValue } from '@nivo/line'
import { GraphQLError } from 'graphql'
import { DateTime } from 'luxon'
import React, { ReactNode } from 'react'
import states from './gf/data/states'

export type Maybe<T> = T | null

export interface GqlError {
  message: string
}

export type StateCode = (typeof states)[number]['code']

export interface Product extends Omit<ProductPayload, 'addedAt' | 'updatedAt' | 'sourceProduct'> {
  addedAt: DateTime
  updatedAt: DateTime
  sourceProduct: SourceProduct
}

export interface Transaction
  extends Omit<
    TransactionPayload,
    'customer' | 'gearflow' | 'supplier' | 'committedAt' | 'storeOrder'
  > {
  customer: Money
  gearflow: Money
  supplier: Money
  committedAt: DateTime
  storeOrder: StoreOrder
}

export interface StoreOrderEventCancelData {
  id: string
  details?: string
}

export interface StoreOrderEventQuoteApprovedData {
  payOnAccount: boolean
  payOnInvoice: boolean
}

export interface StoreOrderEventNotificationSubscriptionData {
  id: string
  name: string
}

export interface StoreOrderEventEditedShippingAddressData {
  previousAddress?: Address
}

export interface StoreOrderEventPayDirectInvoiceSentData {
  link: string
}

export interface RfqEventNotificationSubscriptionData {
  id: string
  name: string
}

export interface RfqEventVendorQuoteFileUploadedData {
  fileUrl: string
  vendorId: string
}

export interface RfqEventViewedReceipt {
  vendorName: string
}

export type RfqEventData =
  | RfqEventNotificationSubscriptionData
  | RfqEventVendorQuoteFileUploadedData
  | RfqEventViewedReceipt

export type RfqEventType =
  | 'created_on_website'
  | 'created_on_dashboard'
  | 'created_on_buyer'
  | 'closed'
  | 'opened'
  | 'cancelled'
  | 'quote_created'
  | 'note'
  | 'assigned'
  | 'assigned_deleted'
  | 'owner_added'
  | 'owner_deleted'
  | 'confirmation_email_resent'
  | 'fulfilled'
  | 'quote_approved'
  | 'buyer_notification_user_added'
  | 'buyer_notification_user_removed'
  | 'created_from_cancellation'
  | 'created_from_integration'
  | 'created_on_mobile'
  | 'shipment_received'
  | 'created_from_csv'
  | 'vendor_quote_file_uploaded'
  | 'viewed_receipt'
  | 'created_by_dealer'
  | 'updated_part_request'
  | 'vendor_closed'
  | 'cannot_participate'
  | 'can_participate'
  | 'added_part_request'
  | 'edited_shipping_address'

export interface RfqEventPayload {
  id: string
  privacy: string
  type: RfqEventType
  user?: User
  store?: Store
  occurredAt?: string
  insertedAt: string
  note: string
  emailSent?: boolean
  requestForQuoteId: string
  requestForQuoteShortId: string
  data?: RfqEventData
}

export interface RfqEvent extends Omit<RfqEventPayload, 'insertedAt' | 'occurredAt'> {
  occurredAt?: DateTime
  insertedAt: DateTime
}

export type StoreOrderEventData =
  | StoreOrderEventCancelData
  | StoreOrderEventQuoteApprovedData
  | StoreOrderEventNotificationSubscriptionData
  | StoreOrderEventEditedShippingAddressData
  | StoreOrderEventPayDirectInvoiceSentData

export interface StoreOrderEvent
  extends Omit<
    StoreOrderEventPayload,
    | 'transaction'
    | 'refund'
    | 'additionalCharge'
    | 'storeOrderAudit'
    | 'diffStoreOrderAudit'
    | 'shipment'
    | 'occurredAt'
    | 'insertedAt'
  > {
  transaction?: Transaction
  refund?: Refund
  additionalCharge?: AdditionalCharge
  storeOrderAudit?: StoreOrderAudit
  diffStoreOrderAudit?: StoreOrderAudit
  shipment?: Shipment
  problem?: Omit<StoreOrderProblem, '__typename'> | null
  data?: StoreOrderEventData
  occurredAt: DateTime
  insertedAt: DateTime
}

export type PaymentStatus =
  | 'unpaid'
  | 'authorized'
  | 'paid'
  | 'refunded'
  | 'partially_refunded'
  | 'cancelled'
  | 'invoiced'
  | 'processing'
  | 'quote_approved'
  | 'direct_pay'

export interface StoreOrderDiscountPayload {
  percent: string
}

export interface StoreOrderDiscount {
  percent: number
}

export interface BaseStoreOrderPayload {
  id?: string
  shortId?: string
  token?: string
  order?: Order
  state?: StoreOrderState
  insertedAt?: string
  updatedAt?: string
  shippingAmount?: MoneyPayload
  convenienceFeeRate?: number
  salesTax?: MoneyPayload
  store?: Store
  balanceTransaction?: BalanceTransaction | null
  additionalCharges?: AdditionalChargePayload[]
  discounts?: StoreOrderDiscountPayload[]
  refunds?: RefundPayload[]
  lineItems?: LineItemPayload[]
  events?: StoreOrderEventPayload[]
  features?: StoreOrderFeatures
  gearflowShipping?: boolean
  purchaseOrder?: string
  paymentMethod?: StoreOrderPaymentMethod
  payOnInvoice?: boolean
  accountMachines?: AccountMachinePayload[]
  vendor?: VendorPayload
  timingDetails?: string
  paymentStatus?: PaymentStatus
  buyerNotificationUsers?: User[]
  invoices?: Omit<InvoicePayload, 'storeOrder' | 'additionalCharge'>[]
  deliveryMethod?: DeliveryMethod | null
  pickup?: boolean
  pickupAddress?: Address | null
  shippingLocation?: ShippingLocation | null
  shippingAddress?: Address | null
  quoteNumber?: string
  curriDeliveries?: CurriDelivery[]
  pendingDelivery?: {
    curriQuoteId: string
    distance: number
    duration: number
    pickupDuration: number
    method: string
    price: Money
    insertedAt: string
  }
  branch?: Pick<Branch, 'billingAddress'> | null
  problems?: Pick<StoreOrderProblem, 'id' | 'type' | 'details' | 'resolvedAt'>[] | null
  total?: MoneyPayload
  stripePaymentIntentId?: string
}

export type StoreOrderPayload = Required<
  Pick<
    BaseStoreOrderPayload,
    | 'id'
    | 'token'
    | 'shortId'
    | 'order'
    | 'state'
    | 'insertedAt'
    | 'updatedAt'
    | 'shippingAmount'
    | 'convenienceFeeRate'
    | 'salesTax'
    | 'store'
    | 'balanceTransaction'
    | 'additionalCharges'
    | 'discounts'
    | 'refunds'
    | 'lineItems'
    | 'events'
    | 'features'
    | 'gearflowShipping'
    | 'purchaseOrder'
    | 'payOnInvoice'
    | 'accountMachines'
    | 'vendor'
    | 'timingDetails'
    | 'paymentStatus'
    | 'buyerNotificationUsers'
    | 'invoices'
    | 'deliveryMethod'
    | 'pickup'
    | 'pickupAddress'
    | 'shippingLocation'
    | 'shippingAddress'
    | 'quoteNumber'
    | 'curriDeliveries'
    | 'pendingDelivery'
    | 'branch'
    | 'problems'
    | 'paymentMethod'
    | 'total'
    | 'stripePaymentIntentId'
  >
>

export interface TransactionPayload {
  id: string
  action: 'charge' | 'refund' | 'payout'
  customer: MoneyPayload
  gearflow: MoneyPayload
  supplier: MoneyPayload
  balanceTransaction: BalanceTransaction
  committedAt: string
  storeOrder: StoreOrderPayload
  supplierInfo?: string
  externalDocumentation: { filename: string; url: string }[]
}

export type StoreOrderEventType =
  | 'created'
  | 'created_on_dashboard'
  | 'created_from_rfq'
  | 'delivery_booked'
  | 'edited'
  | 'quote_approved'
  | 'quote_denied'
  | 'approved'
  | 'pay_direct_invoice_sent'
  | 'refund_created'
  | 'invoice_paid'
  | 'pay_direct_invoice_paid'
  | 'additional_charge_created'
  | 'additional_charge_approved'
  | 'additional_charge_denied'
  | 'shipped'
  | 'shipment_configured'
  | 'cancelled'
  | 'note'
  | 'edited_email_resent'
  | 'confirmation_email_resent'
  | 'buyer_notification_user_added'
  | 'buyer_notification_user_removed'
  | 'edited_shipping_address'
  | 'ready_for_pickup'
  | 'shipment_received'
  | 'edit_shipment'
  | 'availability_updated'
  | 'problem_reported'
  | 'problem_resolved'
  | 'shipment_delivered'
  | 'completed'
  | 'credit_memo'
  | 'credit_memo_received'
  | 'pay_direct_invoice_billed'

export type StoreOrderEventPrivacy = 'BUYER_PROTECTED' | 'SUPPLIER_PROTECTED' | 'PUBLIC' | 'PRIVATE'
export interface StoreOrderEventPayload {
  id: string
  type: StoreOrderEventType
  user?: User
  occurredAt: string
  insertedAt: string
  transaction?: TransactionPayload
  refund?: RefundPayload
  additionalCharge?: AdditionalChargePayload
  shipment?: ShipmentPayload
  storeOrderAudit?: StoreOrderAuditPayload
  diffStoreOrderAudit?: StoreOrderAuditPayload
  note: string
  emailSent?: boolean
  privacy: StoreOrderEventPrivacy
  storeOrderId: string
  storeOrderShortId: string
  storeName: string
}

export interface StoreOrder
  extends Omit<
    StoreOrderPayload,
    | 'shippingAmount'
    | 'salesTax'
    | 'additionalCharges'
    | 'lineItems'
    | 'refunds'
    | 'discounts'
    | 'events'
    | 'insertedAt'
    | 'updatedAt'
    | 'accountMachines'
    | 'vendor'
    | 'invoices'
    | 'total'
  > {
  shippingAmount: Money
  salesTax: Money
  additionalCharges: AdditionalCharge[]
  lineItems: LineItem[]
  refunds: Refund[]
  discounts: StoreOrderDiscount[]
  events: StoreOrderEvent[]
  accountMachines: AccountMachine[]
  insertedAt: DateTime
  updatedAt: DateTime
  vendor?: Vendor
  invoices?: Omit<Invoice, 'storeOrder' | 'additionalCharge'>[]
  total: Money
}

export interface Order {
  insertedAt: string
  updatedAt: string
  id: string
  name?: string
  shortId: string
  email: string
  phoneNumber: string
  state: string
  product: Pick<Product, 'name' | 'shippingCost'>
  billingAddress?: Address
  user?: User
  comment: string
  shippingCost: number
  requestForQuoteId?: string
  requestForQuote?: RequestForQuote
}

export interface LineItemPayload {
  id: string
  product: Product
  quantity: number
  externalId: string | null
  unitPrice: MoneyPayload
  discountedUnitPrice: MoneyPayload
  extendedPrice: MoneyPayload
  shipmentItems: ShipmentItemPayload[]
  showMpn: boolean
  inStock: boolean | null
  availableAt: string | null
}

export interface LineItem
  extends Omit<
    LineItemPayload,
    'unitPrice' | 'discountedUnitPrice' | 'extendedPrice' | 'shipmentItems' | 'availableAt'
  > {
  externalId: string | null
  taskNumber?: string | null
  suggestion?: boolean | null
  unitPrice: Money
  discountedUnitPrice: Money
  extendedPrice: Money
  shipmentItems: ShipmentItem[]
  availableAt: DateTime | null
}

export interface LineItemInput {
  inStock: boolean | null
  availableAt: DateTime | null
  productId: string
  quantity: number
  unitPrice: number | undefined
}

export interface ProductForm
  extends Pick<
    Product,
    | 'description'
    | 'sku'
    | 'mpn'
    | 'altMpn'
    | 'availability'
    | 'enabled'
    | 'leadTime'
    | 'fitsWithMachines'
  > {
  name: string | null
  shortDescription: string | null
  salePrice: number | null
  shippingCost: number | null
  brandId: string | null
  categoryId: string | null
}

export interface UpdateProductForm extends Pick<Product, 'id' | 'enabled' | 'fitsWithMachines'> {
  name: string
  mpn: string
  altMpn: string
  sku: string
  shortDescription: string
  longDescription: string
  quantity: number | null
  availability: Availability | null
  leadTime: string
  leadTimeDate: string | null
  salePrice: Money | null
  shippingCost: Money | null
  brandId: string | null
  categoryId: string | null
  image?: UploadedImage | null
  autoQuoteEnabled: boolean
}

export type UpdateProductErrors = {
  [K in keyof UpdateProductForm]?: string
}

export interface User {
  id: string
  name: string | null
  email: string
  notify: boolean
  role: string
  confirmed?: boolean
  shippingAddress?: Address
  billingAddress: Address
  phoneNumber: string | null
  organization?: Organization
  permissions?: Permission[]
  orgUser?: OrgUser
  title?: string
  smsEnabled: boolean
  notificationSettings: UserNotificationSetting[]
  profileImageUrl: string | null
  emailConfirmed: boolean | null
}

export interface AdminUser {
  id: string
  name: string
  email: string
  phoneNumber: string
  organization?: Pick<Organization, 'id' | 'name'>
  stores?: Pick<Store, 'id' | 'name'>[]
  insertedAt?: DateTime
}

export interface AdminUserPayload {
  id: string
  name: string
  email: string
  phoneNumber: string
  stores?: Pick<Store, 'id' | 'name'>[]
  organization?: Pick<Organization, 'id' | 'name'>
  insertedAt: string
}

export interface StoreLookup {
  id: string
  name: string
  alias?: string
}

export interface Plan {
  id: string
  frequency: string
  baseLicenses: number | null
  basePrice: number | null
  amount: number
  unit: string
  subscriptionType: string
}

export interface Store {
  id: string
  name: string
  city?: string
  state?: string
  description?: string
  alias?: string
  shipsFree: boolean
  users: User[]
  gearflowShipping: boolean
  address?: Address
  email?: string
  phoneNumber?: string
  returnPolicy?: string | null
  shippingInfo?: string | null
  onboardedSuppDashDatetime: string | undefined
  gearflowPaymentsEnabled: boolean
  gearflowPaymentsRequested: boolean
  marketplaceUrl?: string
  storeAccountingEmail?: string
  enabled: boolean
  autoPayout: boolean
  showPartsHub: boolean
  autoRfqAssignments: boolean
  autoQuote: boolean
  showExportOrders: boolean
  defaultTakeRate: number
  vendorTakeRate: number
  brands?: Brand[]
  categories?: { id: string }[]
  logo: { url: string } | null
  banner: { url: string } | null
  hasCatalog?: boolean | null
  rfqStates: StateCode[]
  createdByBuyer: boolean
  dealerHubEnabled: boolean
  isBrokerEnabled: boolean
}

export type FeatureFlagsPayload = Record<string, boolean | undefined>
export type FeatureFlags = Record<string, boolean>

export interface Brand {
  id: string
  name: string
  logoUrl: string | null
}
export interface Machine {
  make: string | null
  model: string | null
  year: number | null
}

export interface Category {
  id: string
  parentId?: string | null
  name: string
}

export type Availability = 'in_stock' | 'back_ordered' | 'out_of_stock'

export interface SourceProductPayload {
  name: string
  mpn: string
  altMpn: string
  sku: string
  shortDescription: string
  longDescription: string
  shippingCost: MoneyPayload | null
  availability: Availability
  leadTime: string
  leadTimeDate: string
  category: Category
  source: Pick<Source, 'name'>
  brand: Brand | null
  images: ProductImage[]
}

export interface SourceProduct extends Omit<SourceProductPayload, 'shippingCost'> {
  shippingCost: Money | null
}

interface IsCustom {
  name: boolean
  mpn: boolean
  altMpn: boolean
  sku: boolean
  shortDescription: boolean
  longDescription: boolean
  shippingCost: boolean
  availability: boolean
  leadTime: boolean
  leadTimeDate: boolean
  category: boolean
  brand: boolean
  image: boolean
}

interface ProductImageVariant {
  name: string
  url: string
}

export interface ProductImage {
  variants: ProductImageVariant[]
}

export interface BaseProductPayload {
  id?: string
  name?: string
  description?: string
  shortDescription?: string
  longDescription?: string
  sku?: string
  mpn?: string
  altMpn?: string | null
  brand?: Brand | null
  category?: Category | null
  salePrice?: number
  listPrice?: number
  shippingCost?: number | null
  availability?: Availability
  quantity?: number | null
  enabled?: boolean
  merchantEnabled?: boolean
  merchantGroup?: string | null
  offline?: boolean
  images?: ProductImage[]
  shippingRate?: string
  addedAt?: string
  updatedAt?: string
  store?: Store
  path?: string
  leadTime?: string
  leadTimeDate?: string
  fitsWithMachines?: Machine[]
  isSource?: boolean
  sourceProduct?: SourceProductPayload
  isCustom?: IsCustom
}

export type BaseProduct = Omit<BaseProductPayload, 'addedAt' | 'updatedAt' | 'sourceProduct'> & {
  addedAt?: DateTime
  updatedAt?: DateTime
  sourceProduct?: SourceProduct
}

export type ProductPayload = Required<
  Pick<
    BaseProductPayload,
    | 'id'
    | 'name'
    | 'description'
    | 'shortDescription'
    | 'longDescription'
    | 'sku'
    | 'mpn'
    | 'altMpn'
    | 'brand'
    | 'category'
    | 'salePrice'
    | 'listPrice'
    | 'shippingCost'
    | 'availability'
    | 'quantity'
    | 'enabled'
    | 'offline'
    | 'images'
    | 'shippingRate'
    | 'addedAt'
    | 'updatedAt'
    | 'store'
    | 'path'
    | 'leadTime'
    | 'leadTimeDate'
    | 'fitsWithMachines'
    | 'isSource'
    | 'sourceProduct'
    | 'isCustom'
  >
>

export interface AdditionalChargePayload {
  id: string
  name: string
  price: MoneyPayload
  state: string
  purchaseOrder: string | null
  invoice?: Omit<InvoicePayload, 'storeOrder' | 'additionalCharge'>
}

export interface AdditionalCharge extends Omit<AdditionalChargePayload, 'price' | 'invoice'> {
  price: Money
  invoice?: Omit<Invoice, 'storeOrder' | 'additionalCharge'>
}

export interface LineItemAuditPayload {
  id: string
  quantity: number
  unitPrice: MoneyPayload
  productId: string
  productName: string
}
export interface LineItemAudit extends Omit<LineItemAuditPayload, 'unitPrice'> {
  unitPrice: Money
}

export interface StoreOrderAuditPayload {
  id: string
  shippingCost: MoneyPayload
  taxCost: MoneyPayload
  timingDetails: string
  pickup: boolean
  lineItemAudits: LineItemAuditPayload[]
}
export interface StoreOrderAudit
  extends Omit<StoreOrderAuditPayload, 'shippingCost' | 'taxCost' | 'lineItemAudits'> {
  shippingCost: Money
  taxCost: Money
  lineItemAudits: LineItemAudit[]
}

interface StoreOrderFeatures {
  txns: boolean
  storeOrderTimelineV1: boolean
}

interface ShipmentLineItem {
  id: string
  quantity: number
  productName: string
}

export interface ShipmentItemPayload {
  id: string
  quantity: number
  lineItem: ShipmentLineItem
  shipment: ShipmentPayload
}

export interface ShipmentItem extends Omit<ShipmentItemPayload, 'shipment'> {
  shipment: Shipment
}

export interface ShipmentLabelPayload {
  id: string
  url: string
  cost: MoneyPayload
  shipDate: string
}

export interface ShipmentLabel extends Omit<ShipmentLabelPayload, 'cost' | 'shipDate'> {
  cost: Money
  shipDate: DateTime
}

export interface ShipmentPayload {
  id: string
  link?: string
  carrier?: string
  tracking?: string
  shipmentItems: Omit<ShipmentItem, 'shipment'>[]
  shippedAt?: string
  receivedAt?: string
  label?: ShipmentLabelPayload
}

export interface Shipment extends Omit<ShipmentPayload, 'shippedAt' | 'label' | 'receivedAt'> {
  shippedAt?: DateTime
  receivedAt?: DateTime
  label?: ShipmentLabel
}

export type AccountMachinePayload = {
  id: string
  name: string
  serialNumber: string | null
  description: string
  owned: boolean
  machine: Machine
  engineMake?: string
  engineModel?: string
  engineSerialNumber?: string
  engineHours?: string
  operator?: string
  lat?: string
  lng?: string
}

export interface AccountMachine extends Machine {
  id: string
  name: string
  serialNumber: string | null
  description: string | null
  owned: boolean
  engineMake?: string | null
  engineModel?: string | null
  engineSerialNumber?: string | null
  engineHours?: string | null
  operator?: string | null
  lat?: string | null
  lng?: string | null
}

type CompanyType =
  | 'AGRICULTURAL'
  | 'AUTOMOTIVE'
  | 'AEROSPACE'
  | 'CONSTRUCTION'
  | 'CONTRACTOR'
  | 'ENERGY'
  | 'RENTAL'
  | 'REPAIR'
  | 'EQUIPMENT_WHOLESALER'
  | 'GOV'
  | 'MANUFACTURER'
  | 'MARINE'
  | 'RETAILER'

type OrgUser = {
  role: string
  allNotifications: boolean
}

type Permission = {
  id: string
  desc: string
  module: string
  action: string
}

type PermissionGroup = {
  id: string
  name: string
}

type AccountUser = {
  id: string
  name: string
  email: string
  title: string
  phoneNumber: string
  orgUser?: OrgUser
  permissionGroups?: PermissionGroup[]
}

interface Survey {
  id: string
  question: { question: string }
  answer: { answer: string }
}

export interface ApproveNetTermPayload {
  id: string
  pending: boolean
  externalUrls: [string]
  finalizedAt?: string
  insertedAt: string
}

export interface ApproveNetTerm extends Omit<ApproveNetTermPayload, 'finalizedAt' | 'insertedAt'> {
  finalizedAt?: DateTime
  insertedAt: DateTime
}

export interface OrganizationPayload {
  id: string
  name: string
  phoneNumber: string
  users: AccountUser[]
  machines?: AccountMachine[]
  autoApproval: boolean
  requireBillingCompany: boolean
  buyerDashboardAccess: boolean
  buyerDashboardAccessExpiresAt: string
  balanceEmail?: string
  showPurchaseOrder?: boolean
  showAddlPurchaseOrder?: boolean
  showEditShipping?: boolean
  approvalThresholdEnabled?: boolean
  deliveryEnabled?: boolean
  deliveryException?: boolean
  showShipOption?: boolean
  approvalThreshold?: Money
  shippingLocations: ShippingLocation[]
  branches: Branch[]
  surveys: Survey[]
  companyType: CompanyType
  invoiceEmail?: string
  openOrdersCount: number
  approveNetTerm?: ApproveNetTermPayload
  closedOrdersCount: number
  lastActivityDate: string
  billingAddress?: Address
  taxExemptCertUrl?: string | null
  logoUrl?: string | null
}

export interface Organization
  extends Omit<
    OrganizationPayload,
    'lastActivityDate' | 'approveNetTerm' | 'buyerDashboardAccessExpiresAt'
  > {
  approveNetTerm?: ApproveNetTerm
  lastActivityDate: DateTime
  buyerDashboardAccessExpiresAt?: DateTime | undefined
}

export interface AddressPayload {
  firstName: string | null
  lastName: string | null
  lineOne: string
  lineTwo: string | null
  city: string
  state: string
  postalCode: string | null
  country: string
  companyName: string | null
  deliverable?: boolean | null
  rdi?: string | null
  latitude?: string
  longitude?: string
  point?: Point | null
}

export interface Address extends Omit<AddressPayload, 'latitude' | 'longitude'> {
  latitude?: number | null
  longitude?: number | null
}

export interface ShippingLocationPayload {
  id: string
  name: string
  code: string | null
  phoneNumber: string
  defaultLocation: boolean
  addressId: string
  branch?: Branch | null
  address: AddressPayload | null
  insertedAt: string
  shippingLocationUsers: Pick<User, 'id' | 'name' | 'email'>[]
  organization: Pick<Organization, 'id' | 'name'>
  deleted: boolean
}
export interface ShippingLocation extends Omit<ShippingLocationPayload, 'insertedAt' | 'address'> {
  insertedAt: DateTime
  address: Address | null
}

export interface Customer {
  id: string
  name: string
  account: Organization
  shippingAddress: Address
  billingAddress: Address
  ordersCount: number
  email: string
  lastOrderId: string
  lastActivityDate: string
  userId: string
  phoneNumber: string
  title: string
}

export interface Pagination {
  totalPages: number
  totalResults: number
}

export type FilterTypeId =
  | 'contains'
  | 'not_contains'
  | 'blank'
  | 'not_blank'
  | 'equals'
  | 'not_equals'
  | 'date_time_in_range'
  | 'true'
  | 'false'
  | 'not_unique'
  | 'too_long'
  | 'in'
  | 'is'
  | 'is_not'

export interface FilterType {
  id: FilterTypeId
  display: string
}

export interface FilterField {
  id: string
  display: string
  filterTypeIds: FilterTypeId[]
  adminOnly?: boolean
  featureFlag?: string
  options?: Option[]
}

export interface Option {
  id: string
  display: string
}

export interface AutocompleteOption {
  value: string
  label: string
}

export interface ContainsFilter {
  id: string
  typeId: 'contains'
  fieldId: string
  text: string
}

export interface NotContainsFilter {
  id: string
  typeId: 'not_contains'
  fieldId: string
  text: string
}

export interface BlankFilter {
  id: string
  typeId: 'blank'
  fieldId: string
}

export interface NotBlankFilter {
  id: string
  typeId: 'not_blank'
  fieldId: string
}

export interface EqualsFilter {
  id: string
  typeId: 'equals'
  fieldId: string
  text: string
}

export interface NotEqualsFilter {
  id: string
  typeId: 'not_equals'
  fieldId: string
  text: string
}

export interface DateTimeInRangeFilter {
  id: string
  typeId: 'date_time_in_range'
  fieldId: string
  from: string | null
  to: string | null
}

export interface TrueFilter {
  id: string
  typeId: 'true'
  fieldId: string
}

export interface FalseFilter {
  id: string
  typeId: 'false'
  fieldId: string
}

export interface IsNotUniqueFilter {
  id: string
  typeId: 'not_unique'
  fieldId: string
}

export interface TooLongFilter {
  id: string
  typeId: 'too_long'
  fieldId: string
}

export interface InFilter {
  id: string
  typeId: 'in'
  fieldId: string
  list: string[]
}

export interface IsFilter {
  id: string
  typeId: 'is'
  fieldId: string
  options: Option[]
  selectedOption: string
}

export interface IsNotFilter {
  id: string
  typeId: 'is_not'
  fieldId: string
  options: Option[]
  selectedOption: string
}

export type Filter =
  | ContainsFilter
  | NotContainsFilter
  | BlankFilter
  | NotBlankFilter
  | EqualsFilter
  | NotEqualsFilter
  | DateTimeInRangeFilter
  | TrueFilter
  | FalseFilter
  | IsNotUniqueFilter
  | TooLongFilter
  | InFilter
  | IsFilter
  | IsNotFilter

export type OrderByOrder = 'asc' | 'desc'
export type OrderBy = {
  id: string
  order: OrderByOrder
}

export type CategoriesDictionary = Record<string, Category[]>

export interface Action {
  id: number | string
  title: string
  callBack: () => void
  type?: string
  disabled?: boolean
  showSpinner?: boolean
}
export interface ElementAction {
  id: number | string
  element: JSX.Element
}

export interface FiltersApi {
  add: (filter: Filter) => void
  remove: (id: string) => void
  updateField: (filter: Filter, field: FilterField) => void
  updateTypeId: (filter: Filter, typeId: FilterTypeId) => void
  updateText: (filter: Filter, text: string) => void
  updateFrom: (filter: Filter, from: string | null) => void
  updateTo: (filter: Filter, to: string | null) => void
  updateOption: (filter: Filter, optionId: string) => void
}

interface Crumb {
  name: string
  href: string
}

export interface Breadcrumbs {
  copy: string
  crumbs: Crumb[]
}

interface FrameProps {
  header?: JSX.Element
  breadcrumbs?: Breadcrumbs
  helpUrl?: string
  children?: React.ReactNode
  className?: string
}

export type FrameC = React.FC<FrameProps>

export type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  setValue?: (value: string) => void
  prose?: boolean
}

export type MsgTone = 'positive' | 'negative' | 'neutral'

export type MsgAction = {
  text: string
  callback: () => void
}

export interface Msg {
  id: string
  text: string
  tone: MsgTone
  action?: MsgAction
  createdAt: Date
}

export interface MsgsMgr {
  add: (text: string, tone?: MsgTone) => void
  addWithAction: (text: string, action: MsgAction, tone?: MsgTone) => void
  addUnknownError: () => void
  remove: (msg: Msg) => void
  clear: () => void
}

export type MessageC = React.FC<{ msg: Msg; removeMsg: (msg: Msg) => void }>

export type FiltersVar = string[][]
export type SearchArgs = {
  page?: number
  filters?: FiltersVar
}

export enum ModalSize {
  SM,
  MD,
  LG,
  XL,
}
export interface ModalFormProps {
  open: boolean
  onClose: () => void
  title: string
  action?: string
  method?: string
  cancelText?: string
  onSubmit: React.FormEventHandler<HTMLFormElement>
  submitButtonText?: string
  submitButtonDisabled?: boolean
  submitButtonShowSpinner?: boolean
  withIcon?: boolean
  children?: React.ReactNode
  submitButtonClassName?: string | undefined
  submitButtonSpinnerClassName?: string | undefined
  cancelButtonClassName?: string | undefined
  size?: ModalSize
}

export type EmailActionModalC = React.FC<{
  open: boolean
  onClose: () => void
  title: string
  onSubmit: () => void
  submitButtonText?: string
  isWaiting: boolean
  emailText: string
  setEmailText: (newEmailText: string) => void
  sendEmailCheckbox: boolean
  setSendEmailCheckbox: (newEmailCheckbox: boolean) => void
  children?: React.ReactNode
}>

export type EmailActionModalFormC = React.FC<{
  open: boolean
  onClose: () => void
  title: string
  onSubmit: React.FormEventHandler<HTMLFormElement>
  submitButtonText?: string
  isWaiting: boolean
  emailText: string
  setEmailText: (newEmailText: string) => void
  sendEmailCheckbox: boolean
  setSendEmailCheckbox: (newEmailCheckbox: boolean) => void
  children?: React.ReactNode
}>

export enum ShippingOptionDisplay {
  Standard = 'Standard',
  Expedited = 'Expedited',
  Overnight = 'Overnight',
  None = 'None',
}
export interface ShippingCost {
  storeId: string | undefined
  shippingCost: string
}

export type EditLineItem = Omit<LineItem, 'id' | 'shipmentItems' | 'inStock' | 'availableAt'> & {
  id?: string
  inStock: boolean | null
  availableAt: DateTime | null
}

export interface EditLineItemChanges {
  [id: string]: EditLineItem
}

export interface ProductSearchResult extends Product {
  inOrder: boolean
}

export interface RefundPayload {
  amount: MoneyPayload
}

export interface Refund extends Omit<RefundPayload, 'amount'> {
  amount: Money
}

interface EventPayloadStoreOrderEvent {
  storeOrderEvent: StoreOrderEventPayload
  requestForQuoteEvent: undefined
}
interface EventPayloadRequestForQuoteEvent {
  storeOrderEvent: undefined
  requestForQuoteEvent: RfqEventPayload
}
export type EventPayload = EventPayloadStoreOrderEvent | EventPayloadRequestForQuoteEvent
interface EventStoreOrderEvent extends Omit<EventPayloadStoreOrderEvent, 'storeOrderEvent'> {
  storeOrderEvent: StoreOrderEvent
}
interface EventRequestForQuoteEvent
  extends Omit<EventPayloadRequestForQuoteEvent, 'requestForQuoteEvent'> {
  requestForQuoteEvent: RfqEvent
}
export type Event = EventStoreOrderEvent | EventRequestForQuoteEvent

export interface ProfileData {
  name: string | null
  email: string
  phone?: string
  phoneNumber?: string | null
}

export interface StoreData {
  shippingInfo: string
  returnPolicy: string
  description: string
}

export interface LineItemAuditDiff {
  old?: LineItemAudit
  new?: LineItemAudit
}

export interface StoreOrderAuditDiff {
  oldShippingCost: Money
  newShippingCost: Money
  oldTimingDetails: string
  newTimingDetails: string
  oldPickup: boolean
  newPickup: boolean
  lineItemDiffs: LineItemAuditDiff[]
}

export interface CreateOrderFormCustomerShim {
  name: string
  email: string
  phone: string
}

export interface CreateOrderFormCustomer {
  id: string
  name: string
  email: string
  shippingAddress: Address
  billingAddress: Address | null
  phoneNumber: string
  userId?: string
  account?: { id: string }
}

export type CurrencyCode = 'USD'

export interface Currency {
  code: CurrencyCode
}

export interface MoneyPayload {
  amount: number
  currency: string
}

export interface Money extends Omit<MoneyPayload, 'currency'> {
  currency: Currency
}

export type TMoney = Money

export interface TransactionsSearchPayload {
  transactions: TransactionPayload[]
  pagination: Pagination
}

export interface TransactionsSearch extends Omit<TransactionsSearchPayload, 'transactions'> {
  transactions: Transaction[]
}

export interface BalanceTransaction {
  id: string
  token: string
}

export interface BalanceActionDataset {
  transactions: BalanceTransaction[]
  creditLimit:
    | (Pick<CreditLimit, 'status'> & {
        creditLimit: string
        maxCreditLimit: string
      })
    | null
}

type BalancePaymentMethod =
  | 'creditCard'
  | 'bank'
  | 'check'
  | 'payWithTerms'
  | 'invoice'
  | 'pads'
  | 'paySupplier'

export interface BalanceConfig {
  skipSuccessPage?: boolean
  hideDueDate?: boolean
  hideDueDateText?: string
  allowedPaymentMethods?: BalancePaymentMethod[]
  isAuth?: boolean
  onError?: (error: unknown) => void
  onCancel?: () => void
  onSuccess?: () => void
  // onClose is required for some reason, even though it doesn't need to do anything
  onClose: () => void
}

export interface Balance {
  checkout: {
    init: (config: BalanceConfig) => void
    render: (checkoutToken: string, div_id: string) => void
    destroy: () => void
  }
}

export interface AddressSuggestion {
  lineOne: string
  lineTwo: string
  city: string
  state: string
  postalCode: string
  entries: number
}

export interface InternationalAddressSuggestion {
  addressId: string
  addressText: string
  entries: number
}

export interface AutocompleteSuggestion {
  display: string
  suggestion: AddressSuggestion | InternationalAddressSuggestion
}

export type CustomerCardRef = {
  focusShippingAddress: () => void
}

export interface Point {
  lat: number
  lng: number
}

export interface VendorContact {
  id: string
  name: string | null
  phoneNumber: string
  email: string
  preferred: boolean
}

export interface VendorPayload {
  id: string
  name: string
  contactName: string | null
  phoneNumber: string
  emailAddress: string
  accountNumbers: string[]
  preferredFor: PreferredSetting
  insertedAt: string
  storeLogoUrl: string | null
  address?: AddressPayload
  store?: Pick<
    Store,
    'id' | 'address' | 'gearflowPaymentsEnabled' | 'name' | 'returnPolicy' | 'shippingInfo'
  >
  brands?: Brand[]
  location?: Point
  organization?: OrganizationPayload
  contacts?: VendorContact[]
}

export interface Vendor extends Omit<VendorPayload, 'insertedAt' | 'address' | 'organization'> {
  insertedAt: DateTime
  organization?: Organization
  address?: Address
}

interface RequestForQuoteStore {
  id: string
  store: Store
  storeId: string
}

export interface PartRequest {
  id: string
  mpn: string
  description: string
  quantity: number
  externalId?: string | null
  taskNumber?: string | null
  suggestion?: boolean | null
}

export interface RequestForQuotePayload {
  id: string
  shortId: string
  fullName: string
  phoneNumber: string
  emailAddress?: string
  zipCode?: string
  machineInformation: string
  partsRequest: string
  insertedAt: string
  neededBy: string | null
  broadcast: boolean
  needsApproval?: boolean
  enabled: boolean
  closed: boolean
  userId?: string
  user?: User
  events: RfqEventPayload[]
  storeOrders: StoreOrderPayload[]
  requestForQuoteStores: RequestForQuoteStore[]
  requestForQuoteOwners: {
    id: string
    user: { name: string }
    userId: string
  }[]
  owners: User[]
  orgMachines: AccountMachinePayload[]
  quotesReceived: number
  quotesApproved: number
  shippingOption?: ShippingOptions
  urgency?: 1 | 2 | 3
  images?: { url: string }[]
  buyerNotificationUsers: User[]
  partRequests: PartRequest[]
  vendorQuoteFiles?: VendorQuoteFilePayload[]
  requestForQuoteVendors?: {
    pickup: boolean
    vendorId: string
    vendor: Pick<Vendor, 'id' | 'accountNumbers' | 'name' | 'contactName' | 'address' | 'store'>
    pickupAddress?: Address
    customerNote?: string | null
  }[]
  machineDown?: boolean
  branch?: Branch | null
  brokerRequestForQuoteId?: string | null
  brokeredByRequestForQuotes?: RequestForQuotePayload[]
}

export interface RequestForQuote
  extends Omit<
    RequestForQuotePayload,
    | 'storeOrders'
    | 'events'
    | 'requestForQuoteStores'
    | 'insertedAt'
    | 'neededBy'
    | 'shippingOption'
    | 'vendorQuoteFiles'
    | 'brokeredByRequestForQuotes'
  > {
  insertedAt: DateTime
  neededBy: DateTime | null
  events: RfqEvent[]
  storeOrders: StoreOrder[]
  requestForQuoteStores: RequestForQuoteStore[]
  accountMachines: AccountMachine[]
  shippingAddress?: Address | null
  shippingOption?: ShippingOptionDisplay
  vendorQuoteFiles?: VendorQuoteFile[]
  brokeredByRequestForQuotes?: { id: string }[]
}

export interface SupplierUser {
  id: string
  name: string
  email: string
}

export interface OwnerOption {
  value: string
  label: string
}

export interface StoreOption {
  value: string
  label: string
}

export type WeightUnit = 'ounce' | 'pound' | 'gram' | 'kilogram'
export interface Weight {
  unit: WeightUnit
  value: number
}

export type DimensionsUnit = 'inch' | 'centimeter'
export interface Dimensions {
  unit: DimensionsUnit
  length: number
  width: number
  height: number
}

export type ShippingLabelSize = '4x6' | 'letter'

export interface ShipmentItemInput {
  lineItemId: string
  quantity: number
}

export interface GroundShipmentPayload {
  storeOrderId: string
  shipToAddress: Address
  shipToPhone: string
  shipFromAddress: Address
  shipFromPhone: string
  weight: Weight
  dimensions: Dimensions
  shipmentItems: ShipmentItemInput[]
  labelSize: ShippingLabelSize
  shipDate: string
}

export interface ShippingPayload {
  storeOrderId: string
  shipmentItems: ShipmentItemInput[]
  trackingNumber?: string
  shippingCarrier?: string
  trackingLink?: string
  sendEmail: boolean
  notes: string
}

export interface CancelReason {
  id: string
  title: string
}

export interface MetricsVars {
  fromDate: string
  toDate: string
  selectedStoreId: string
}

export interface MetricsLineChartVars extends MetricsVars {
  periodDays: number
}

interface CancelledStoreOrderEventsGroup {
  id: string
  count: number
  percent: number
}
interface CancelledStoreOrderEventOtherReason {
  details: string
  storeOrderId: string
}
export interface CancellationMetrics {
  allStoreOrdersCount: number
  cancelledStoreOrdersCount: number
  cancelledStoreOrdersPercent: number
  cancelledStoreOrderEventsGroupCount: CancelledStoreOrderEventsGroup[]
  cancelledStoreOrderEventsOtherReason: CancelledStoreOrderEventOtherReason[]
}
export interface CancellationMetricsChartDatum {
  from: string
  to: string
  cancellationMetrics: CancellationMetrics
}

export interface UseSelection {
  all: boolean
  selectedIds: string[]
  isSelected: (id: string) => boolean
  selectAll: () => void
  deselectAll: () => void
  toggle: (id: string) => void
  togglePage: () => void
}

interface StoreLink {
  store: Pick<Store, 'id' | 'name'>
  primary: boolean
}

type SourceUser = Pick<User, 'id' | 'email'>

export interface Source {
  id: string
  name: string
  convictionalId: string
  storeLinks: StoreLink[]
  users: SourceUser[]
}

export interface EventAction {
  title: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  // store order states where this action is hidden
  hiddenForStates: string[]
}
export enum EventType {
  RFQ,
  StoreOrder,
}
export interface EventDisplayData {
  type: EventType
  sourceLink: string
  sourceLinkMessage: string | null
  id: string
  content: JSX.Element | string
  details?: JSX.Element | string | null
  hiddenDetails?: JSX.Element | string | null
  subContent?: JSX.Element | string
  datetime: DateTime
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element | React.ReactElement // heroicon type
  iconClassName?: string
  action?: EventAction
  iconBackground: string
}

export type MatchPath = {
  app: AppName | undefined
  path: string
}

export type AppName = 'admin' | 'suppliers' | 'buyers' | 'oems' | 'developers' | 'dealers'

export interface UploadedImage {
  id: string
  url: string
  name: string
  size: number
  type?: string
}

export interface InvoicePayload {
  id: string
  link: Maybe<string>
  dueDate: Maybe<string>
  totalPrice: MoneyPayload
  isFinanced: boolean
  isPaid: boolean
  isCanceled: boolean
  balanceTransactionId?: Maybe<string>
  balanceInvoiceId?: string
  storeOrder?: StoreOrderPayload
  additionalCharge?: AdditionalChargePayload
}

export interface Invoice
  extends Omit<InvoicePayload, 'dueDate' | 'totalPrice' | 'storeOrder' | 'additionalCharge'> {
  dueDate: Maybe<DateTime>
  totalPrice: Money
  storeOrder?: StoreOrder
  additionalCharge?: AdditionalCharge
}

export type PartRequestInput = Omit<PartRequest, 'id'> & {
  key: number
}

export interface UpdateCategoryForm {
  id: string
  name: string
}

export interface FilterTab {
  name: string
  filters: Filter[]
  orderBy?: OrderBy
  notification?: number
}

export interface Tab {
  name: string
  showNotification?: boolean
}

type ChartRangeOptionId = 'last_7_days' | 'last_30_days' | 'last_90_days'

export interface ChartRangeOption {
  id: ChartRangeOptionId
  display: string
  fromDate: Date
  toDate: Date
  periodDays: number
  intervalDays: number
}

type ComparisonDisplayType = 'percent' | 'value' | 'money'

export interface ComparisonProps {
  value: number | Money
  comparisonValue: number | Money
  className?: string
  displayType?: ComparisonDisplayType
  downIsGood?: boolean
  textColorGood?: string
  textColorBad?: string
  textColorNeutral?: string
  toFixed?: number
}

export interface ReportingTabSectionTab {
  name: string
  children: ReactNode
}
export interface ReportingTabSection {
  name: string
  tabs: ReportingTabSectionTab[]
}

type SuppliersRfqReportingTabDatumKey = 'averageTimeToQuote' | 'rfqsQuotedCount'
type SuppliersOrderReportingTabDatumKey = 'total' | 'netTotal'
export interface ReportingTab {
  name: string
  chartDatumKey: SuppliersRfqReportingTabDatumKey | SuppliersOrderReportingTabDatumKey
  comparisonMarkerLegend: string
  yLegend: string
  yFormat?: ValueFormat<DatumValue>
  xLegend: string
  yDataFormat?: ValueFormat<DatumValue>
}

interface MessagePayload {
  id: string
  text: string
  attachmentUrls: string[]
  user: User
  insertedAt: string
}

interface Message extends Omit<MessagePayload, 'insertedAt'> {
  insertedAt: DateTime
}

interface ConversationCommon {
  id: string
  name: string
  admin: boolean
  unreadMessages: boolean
  lastMessage?: Message
}

type NewConversationStoreOrder = Pick<
  StoreOrder,
  'id' | 'shortId' | 'state' | 'pickup' | 'pickupAddress'
> & {
  store: Pick<StoreOrder['store'], 'id'>
}

export type NewConversationStore = Pick<Store, 'id' | 'name' | 'returnPolicy' | 'shippingInfo'>

type NewConversationRfq = Pick<
  RequestForQuote,
  'id' | 'shortId' | 'fullName' | 'phoneNumber' | 'shippingAddress'
> & {
  storeOrders: (Pick<
    RequestForQuote['storeOrders'][number],
    'id' | 'shortId' | 'pickup' | 'state' | 'pickupAddress'
  > & {
    store: Pick<RequestForQuote['storeOrders'][number]['store'], 'id' | 'name'>
  })[]
  orgMachines: Pick<RequestForQuote['orgMachines'][number], 'id' | 'name' | 'serialNumber'>[]
  user?:
    | (Pick<Exclude<RequestForQuote['user'], undefined>, 'id' | 'name' | 'phoneNumber'> & {
        organization?: Pick<
          Exclude<Exclude<RequestForQuote['user'], undefined>['organization'], undefined>,
          'name'
        >
      })
    | null
}

export interface NewConversation extends ConversationCommon {
  requestForQuote: NewConversationRfq | undefined
  storeOrder: NewConversationStoreOrder | undefined
  store: NewConversationStore | undefined
  unreadMessages: false
  lastMessage: undefined
  newConversation: 'true'
}

type Conversation = Pick<ConversationT, 'id' | 'unreadMessages' | 'admin' | 'name'> & {
  requestForQuote:
    | (Pick<RequestForQuoteT, 'id' | 'shortId' | 'fullName' | 'phoneNumber'> & {
        step: BuyersRequestForQuoteStep | DealersRequestForQuoteStep
        orgMachines: Pick<OrgMachineT, 'id' | 'name' | 'serialNumber'>[]
        user:
          | (Pick<UserT, 'id' | 'name' | 'phoneNumber'> & {
              organization: Pick<OrganizationT, 'id' | 'name'> | null
            })
          | null
        shippingAddress: Pick<
          AddressT,
          | 'firstName'
          | 'lastName'
          | 'companyName'
          | 'lineOne'
          | 'lineTwo'
          | 'city'
          | 'state'
          | 'postalCode'
          | 'country'
          | 'deliverable'
        > | null
        storeOrders: (Pick<StoreOrderT, 'id' | 'shortId' | 'state' | 'pickup'> & {
          store: Pick<StoreT, 'id' | 'name'>
          pickupAddress: Pick<
            AddressT,
            | 'firstName'
            | 'lastName'
            | 'companyName'
            | 'lineOne'
            | 'lineTwo'
            | 'city'
            | 'state'
            | 'postalCode'
            | 'country'
          > | null
        })[]
      })
    | null
  storeOrder:
    | (Pick<StoreOrderT, 'id' | 'shortId' | 'state' | 'pickup'> & {
        store: Pick<StoreT, 'id' | 'name'>
        shippingAddress: Pick<
          AddressT,
          | 'firstName'
          | 'lastName'
          | 'companyName'
          | 'lineOne'
          | 'lineTwo'
          | 'city'
          | 'state'
          | 'postalCode'
          | 'country'
          | 'deliverable'
        > | null
        pickupAddress: Pick<
          AddressT,
          | 'firstName'
          | 'lastName'
          | 'companyName'
          | 'lineOne'
          | 'lineTwo'
          | 'city'
          | 'state'
          | 'postalCode'
          | 'country'
        > | null
        order: Pick<OrderT, 'name' | 'phoneNumber'> & {
          user:
            | (Pick<UserT, 'id' | 'name' | 'phoneNumber'> & {
                organization: Pick<OrganizationT, 'id' | 'name'> | null
              })
            | null
        }
      })
    | null
  store: Pick<StoreT, 'id' | 'name' | 'shippingInfo' | 'returnPolicy'> | null
  lastMessage: Pick<MessageT, 'insertedAt' | 'text' | 'attachmentUrls'> | null
}

export interface DetailsForConversationProps {
  conversation: Conversation | NewConversation
  user: Pick<User, 'id' | 'role'> & { claimed?: boolean }
}

export type ConversationRowPartyType = 'admin' | 'buyer' | 'supplier' | 'internal_org'
export interface ConversationRowParty {
  name: string
  type: ConversationRowPartyType
}
export interface ConversationRowContent {
  name: string | string[] | ConversationRowParty
  details?: ConversationRowParty | ConversationRowParty[]
}

export interface UseToggleActions {
  toggle: () => void
  on: () => void
  off: () => void
}

export interface Branch {
  id: string
  name: string
  code: string
  balanceEmail: string
  balanceBuyerId: string
  shippingLocations: Omit<ShippingLocation, 'branch'>[]
  billingAddress: Address | null
}

type MessageNotificationSettingId = 'message_created'

type RequestForQuoteNotificationSettingId =
  | 'rfq_created'
  | 'rfq_viewed_receipt'
  | 'rfq_cancelled'
  | 'rfq_assigned'
  | 'vendor_unparticipated_in_rfq'

type StoreOrderNotificationSettingId =
  | 'store_order_created_confirmation'
  | 'store_order_quote_created'
  | 'store_order_edited'
  | 'store_order_cancelled'
  | 'store_order_processing'
  | 'store_order_shipped'
  | 'store_order_ready_for_pickup'
  | 'store_order_additional_charge_created'
  | 'store_order_refund_created'
  | 'store_order_problem_reported'
export type UserNotificationSettingId =
  | MessageNotificationSettingId
  | RequestForQuoteNotificationSettingId
  | StoreOrderNotificationSettingId

type UserNotificationSettingScope = 'all_activity' | 'subscribed'

export interface UserNotificationSetting {
  key: UserNotificationSettingId
  email: boolean
  sms: boolean
  pushNotification: boolean
  scope: UserNotificationSettingScope
}

interface ReportingRfqResultPayload {
  timeToQuote: number
  storeOrder: StoreOrderPayload
  requestForQuote: RequestForQuotePayload
}

export interface ReportingRfqResult
  extends Omit<ReportingRfqResultPayload, 'storeOrder' | 'requestForQuote'> {
  storeOrder: StoreOrder
  requestForQuote: RequestForQuote
}

export interface ReportingStoreResult {
  store: Store
  averageTimeToQuote?: number
  rfqsQuotedCount: number
}

export interface ReportingOwnerResult {
  owner?: User
  averageTimeToQuote?: number
  rfqsQuotedCount: number
}

export interface RfqReportingChartDatum {
  averageTimeToQuote: number | null
  rfqsQuotedCount: number | null
  // The chart uses the date as a string, so no need to convert it to a DateTime
  date: string
}
export interface RfqReportingMetricsPayload {
  averageTimeToQuote?: number
  rfqsQuotedCount: number
  chartData: RfqReportingChartDatum[]
  allRfqs: ReportingRfqResultPayload[]
  ownersBreakdown: ReportingOwnerResult[]
  storesBreakdown: ReportingStoreResult[]
}
export interface RfqReportingMetrics extends Omit<RfqReportingMetricsPayload, 'allRfqs'> {
  allRfqs: ReportingRfqResult[]
}

interface ReportingOrderResultPayload {
  total: MoneyPayload
  netTotal: MoneyPayload
  storeOrder: StoreOrderPayload & { step: DealersStoreOrderStep }
  requestForQuote?: RequestForQuotePayload
}

export interface ReportingOrderResult
  extends Omit<
    ReportingOrderResultPayload,
    'total' | 'netTotal' | 'storeOrder' | 'requestForQuote'
  > {
  total: Money
  netTotal: Money
  storeOrder: StoreOrder
  requestForQuote?: RequestForQuote
}

interface OrderReportingChartDatumPayload {
  total: MoneyPayload | null
  netTotal: MoneyPayload | null
  // The chart uses the date as a string, so no need to convert it to a DateTime
  date: string
}
export interface OrderReportingChartDatum
  extends Omit<OrderReportingChartDatumPayload, 'total' | 'netTotal'> {
  total: Money | null
  netTotal: Money | null
}
export interface OrderReportingMetricsPayload {
  total: MoneyPayload
  netTotal: MoneyPayload
  chartData: OrderReportingChartDatumPayload[]
  allStoreOrders: ReportingOrderResultPayload[]
}

export type CardStatus = 'spinner' | 'success' | 'error' | 'none'
export type CardProps = {
  title?: JSX.Element | string
  subtitle?: JSX.Element
  className?: string
  innerClassName?: string
  action?: Action
  headerButton?: JSX.Element
  primaryFooterAction?: Action | ElementAction
  secondaryFooterActions?: (Action | ElementAction)[]
  sectioned?: boolean
  tabs?: Tab[]
  currentTabName?: string
  onTabSelect?: (selectedTabName: string) => void
  children?: React.ReactNode
  status?: CardStatus
  statusSpinnerClassName?: string | undefined
}

type AddressInput = Omit<Address, '__typename' | 'latitude' | 'longitude'>

export type CustomerInput = {
  id?: string
  name: string
  email: string
  shippingAddress: AddressInput
  billingAddress: AddressInput | null
  phoneNumber: string
}

export type HeroIcon = (props: React.ComponentProps<'svg'>) => JSX.Element
export type SvgIcon = (props: React.ComponentProps<'svg'>) => JSX.Element
type SideNavLink = {
  title: string
  to: string
  icon: HeroIcon
}
export type SideNavSection = {
  title: string
  links: SideNavLink[]
}

export type VendorQuoteFilePayload = {
  insertedAt: string
  fileUrl: string
  vendorId: string
}
export type VendorQuoteFile = Omit<VendorQuoteFilePayload, 'insertedAt'> & {
  insertedAt: DateTime
}

export enum ShowMore {
  None = 'none',
  Hidden = 'hidden',
  Visible = 'visible',
}

export enum PaymentMethod {
  NetTerms = 'net terms',
  CreditCardBank = 'credit card/bank debit',
  PayDirect = 'direct pay',
  PayInvoice = 'pay invoice,',
}

type CurriDelivery = {
  curriDeliveryId: string
  trackingUrl: string
  price: Money
}

interface GraphQLErrorExtended extends GraphQLError {
  fields: { name: string; value: string[] }[]
}

export interface ApolloErrorWithMessages extends ApolloError {
  graphQLErrors: GraphQLErrorExtended[]
}

// Filters

export type SimpleFltrPayload = [string]
export type TextFltrPayload = [string, string]
export type MachineFltrPayload = [string, string]
export type LocationFltrPayload = [string, string]
export type UserFltrPayload = [string, string]
export type DateTimeBetweenFltrPayload = [string, string | null, string | null]
export type FltrPayload = SimpleFltrPayload | TextFltrPayload | DateTimeBetweenFltrPayload

export interface SimpleFltr {
  type: 'simple'
  name: string
  args: never[]
}

export interface TextFltr {
  type: 'text'
  name: string
  args: [string]
}

export interface DateTimeBetweenFltr {
  type: 'date_time_between'
  name: string
  args: [DateTime | null, DateTime | null]
}

export type MachineFltr = Omit<TextFltr, 'type'> & { type: 'machine' }
export type LocationFltr = Omit<TextFltr, 'type'> & { type: 'location' }
export type UserFltr = Omit<TextFltr, 'type'> & { type: 'user' }

export type Fltr =
  | SimpleFltr
  | TextFltr
  | DateTimeBetweenFltr
  | MachineFltr
  | LocationFltr
  | UserFltr

export interface FltrOption {
  label: string
  name: string
  type: 'simple' | 'text' | 'date_time_between' | 'machine' | 'location' | 'user'
  group?: string
}

export enum SelectStoreOrderProblemType {
  None = 'NONE',
}
export type SelectStoreOrderProblem = StoreOrderProblemType | SelectStoreOrderProblemType
