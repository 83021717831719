import { gql } from '@apollo/client'

const DELETE_ASSIGNED_RFQ = gql`
  mutation DeleteAssignedRfq($assignedStoreId: String, $requestForQuoteId: String) {
    deleteAssignedRfq(assignedStoreId: $assignedStoreId, requestForQuoteId: $requestForQuoteId)
  }
`

const DELETE_OWNER_RFQ = gql`
  mutation DeleteOwnerRfq($ownerId: String, $requestForQuoteId: String) {
    deleteOwnerRfq(ownerId: $ownerId, requestForQuoteId: $requestForQuoteId) {
      id
    }
  }
`

const ADD_OWNER_RFQ = gql`
  mutation AddOwnerRfq($ownerIds: [String], $requestForQuoteId: String) {
    addOwnerRfq(ownerIds: $ownerIds, requestForQuoteId: $requestForQuoteId) {
      id
    }
  }
`

const ADVANCED_CREATE_RFQ_ON_BEHALF_OF = gql`
  mutation AdvancedCreateRfq(
    $name: String
    $email: String
    $phoneNumber: String
    $partsRequest: String
    $accountMachineId: String
    $vendorIds: [String]
    $shippingLocationIds: [String]
    $shippingAddress: AddressInput
    $shippingOption: ShippingOptions
    $imageUrls: [String]
    $categoryId: ID
    $partRequests: [PartRequestInput]
    $onBehalfOfUserId: String
  ) {
    advancedCreateRfq(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      partsRequest: $partsRequest
      accountMachineId: $accountMachineId
      vendorIds: $vendorIds
      shippingLocationIds: $shippingLocationIds
      shippingAddress: $shippingAddress
      shippingOption: $shippingOption
      imageUrls: $imageUrls
      categoryId: $categoryId
      partRequests: $partRequests
      onBehalfOfUserId: $onBehalfOfUserId
    ) {
      id
    }
  }
`
export interface CreateRfqVars {
  name: string
  email: string
  phoneNumber: string
  machineInformation?: string
  partsRequest: string
  zipCode: string
  code?: string
  userId?: string
  accountMachineId?: string
}

export interface CreateRfqData {
  id: string
}

const CREATE_RFQ = gql`
  mutation CreateRfq(
    $name: String
    $email: String
    $phoneNumber: String
    $machineInformation: String
    $partsRequest: String
    $zipCode: String
    $code: String
    $userId: String
    $accountMachineId: String
  ) {
    createRfq(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      machineInformation: $machineInformation
      partsRequest: $partsRequest
      zipCode: $zipCode
      code: $code
      userId: $userId
      accountMachineId: $accountMachineId
    ) {
      id
    }
  }
`

const CANCEL_RFQ = gql`
  mutation CancelRfq(
    $sendEmail: Boolean
    $reason: String
    $cancelRfqReason: String
    $requestForQuoteId: String
  ) {
    cancelRfq(
      sendEmail: $sendEmail
      reason: $reason
      cancelRfqReason: $cancelRfqReason
      requestForQuoteId: $requestForQuoteId
    )
  }
`

const CLOSE_RFQ = gql`
  mutation CloseRfq($requestForQuoteId: String) {
    closeRfq(requestForQuoteId: $requestForQuoteId) {
      id
    }
  }
`

const ASSIGN_RFQ = gql`
  mutation AssignRfq($storeIds: [String], $requestForQuoteId: String) {
    assignRfq(storeIds: $storeIds, requestForQuoteId: $requestForQuoteId)
  }
`

const ADD_TIMELINE_NOTE = gql`
  mutation AddTimelineNote(
    $note: String
    $privacy: String
    $requestForQuoteId: String
    $storeId: String
  ) {
    addTimelineNote(
      note: $note
      privacy: $privacy
      requestForQuoteId: $requestForQuoteId
      storeId: $storeId
    ) {
      id
      insertedAt
      type
      user {
        name
      }
      note
    }
  }
`

export {
  ADD_OWNER_RFQ,
  ADD_TIMELINE_NOTE,
  ADVANCED_CREATE_RFQ_ON_BEHALF_OF,
  ASSIGN_RFQ,
  CANCEL_RFQ,
  CLOSE_RFQ,
  CREATE_RFQ,
  DELETE_ASSIGNED_RFQ,
  DELETE_OWNER_RFQ,
}
