import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum App {
  PartsHub = 'PARTS_HUB',
  SalesHub = 'SALES_HUB'
}

export type GoogleLink = {
  __typename: 'GoogleLink';
  me: GoogleMe;
};

export type GoogleMe = {
  __typename: 'GoogleMe';
  email: Scalars['String']['output'];
};

export type MicrosoftLink = {
  __typename: 'MicrosoftLink';
  me: MicrosoftMe;
};

export type MicrosoftMe = {
  __typename: 'MicrosoftMe';
  givenName: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mail: Scalars['String']['output'];
  mobilePhone: Maybe<Scalars['String']['output']>;
  surname: Maybe<Scalars['String']['output']>;
};

export type RootMutationType = {
  __typename: 'RootMutationType';
  redeemOauthCode: Scalars['ID']['output'];
  signUp: SignUpResult;
  ssoSignIn: Maybe<Scalars['String']['output']>;
  ssoSignUp: SignUpResult;
  unclaimedUserSignUpAttempted: Maybe<Scalars['Boolean']['output']>;
};


export type RootMutationTypeRedeemOauthCodeArgs = {
  code: Scalars['String']['input'];
  linkType: SsoLinkType;
};


export type RootMutationTypeSignUpArgs = {
  app: App;
  code: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  emailOptIn: Scalars['Boolean']['input'];
  membershipId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type RootMutationTypeSsoSignInArgs = {
  linkId: Scalars['ID']['input'];
  linkType: SsoLinkType;
};


export type RootMutationTypeSsoSignUpArgs = {
  app: App;
  code: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  emailOptIn: Scalars['Boolean']['input'];
  linkId: Scalars['ID']['input'];
  linkType: SsoLinkType;
  membershipId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type RootMutationTypeUnclaimedUserSignUpAttemptedArgs = {
  userId: Scalars['ID']['input'];
};

export type RootQueryType = {
  __typename: 'RootQueryType';
  googleLink: GoogleLink;
  microsoftLink: Maybe<MicrosoftLink>;
  ping: Scalars['Boolean']['output'];
  user: Maybe<User>;
};


export type RootQueryTypeGoogleLinkArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeMicrosoftLinkArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeUserArgs = {
  email: InputMaybe<Scalars['String']['input']>;
};

export type SignUpError = {
  __typename: 'SignUpError';
  companyName: Maybe<Array<Scalars['String']['output']>>;
  email: Maybe<Array<Scalars['String']['output']>>;
  emailOptIn: Maybe<Array<Scalars['String']['output']>>;
  membershipId: Maybe<Array<Scalars['String']['output']>>;
  name: Maybe<Array<Scalars['String']['output']>>;
  password: Maybe<Array<Scalars['String']['output']>>;
  passwordConfirmation: Maybe<Array<Scalars['String']['output']>>;
  phoneNumber: Maybe<Array<Scalars['String']['output']>>;
};

export type SignUpOk = {
  __typename: 'SignUpOk';
  userId: Scalars['ID']['output'];
};

export type SignUpResult = SignUpError | SignUpOk;

export enum SsoLinkType {
  Google = 'GOOGLE',
  Microsoft = 'MICROSOFT'
}

export type User = {
  __typename: 'User';
  claimed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = { __typename: 'RootQueryType', ping: boolean };

export type GoogleLinkQueryVariables = Exact<{
  linkId: Scalars['ID']['input'];
}>;


export type GoogleLinkQuery = { __typename: 'RootQueryType', googleLink: { __typename: 'GoogleLink', me: { __typename: 'GoogleMe', email: string } } };

export type MicrosoftLinkQueryVariables = Exact<{
  linkId: InputMaybe<Scalars['ID']['input']>;
}>;


export type MicrosoftLinkQuery = { __typename: 'RootQueryType', microsoftLink: { __typename: 'MicrosoftLink', me: { __typename: 'MicrosoftMe', id: string, givenName: string | null, surname: string | null, mail: string, mobilePhone: string | null } } | null };

export type RedeemOAuthCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
  linkType: SsoLinkType;
}>;


export type RedeemOAuthCodeMutation = { __typename: 'RootMutationType', redeemOauthCode: string };

export type SsoSignInMutationVariables = Exact<{
  linkId: Scalars['ID']['input'];
  linkType: SsoLinkType;
}>;


export type SsoSignInMutation = { __typename: 'RootMutationType', ssoSignIn: string | null };

export type ExistingUserQueryVariables = Exact<{
  email: InputMaybe<Scalars['String']['input']>;
}>;


export type ExistingUserQuery = { __typename: 'RootQueryType', user: { __typename: 'User', id: string, claimed: boolean } | null };

export type PasswordSignUpMutationVariables = Exact<{
  app: App;
  membershipId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  emailOptIn: Scalars['Boolean']['input'];
  code: Scalars['String']['input'];
}>;


export type PasswordSignUpMutation = { __typename: 'RootMutationType', signUp: { __typename: 'SignUpError', membershipId: Array<string> | null, companyName: Array<string> | null, name: Array<string> | null, email: Array<string> | null, phoneNumber: Array<string> | null, password: Array<string> | null, passwordConfirmation: Array<string> | null, emailOptIn: Array<string> | null } | { __typename: 'SignUpOk', userId: string } };

export type SsoSignUpMutationVariables = Exact<{
  app: App;
  linkId: Scalars['ID']['input'];
  linkType: SsoLinkType;
  membershipId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  emailOptIn: Scalars['Boolean']['input'];
  code: Scalars['String']['input'];
}>;


export type SsoSignUpMutation = { __typename: 'RootMutationType', ssoSignUp: { __typename: 'SignUpError', membershipId: Array<string> | null, companyName: Array<string> | null, name: Array<string> | null, email: Array<string> | null, phoneNumber: Array<string> | null, password: Array<string> | null, passwordConfirmation: Array<string> | null, emailOptIn: Array<string> | null } | { __typename: 'SignUpOk', userId: string } };

export type UnclaimedUserSignUpAttemptedMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UnclaimedUserSignUpAttemptedMutation = { __typename: 'RootMutationType', unclaimedUserSignUpAttempted: boolean | null };


export const PingDocument = gql`
    query Ping {
  ping
}
    `;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, options);
      }
export function usePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(PingDocument, options);
        }
export function usePingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PingQuery, PingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PingQuery, PingQueryVariables>(PingDocument, options);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingSuspenseQueryHookResult = ReturnType<typeof usePingSuspenseQuery>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;
export function refetchPingQuery(variables?: PingQueryVariables) {
      return { query: PingDocument, variables: variables }
    }
export const GoogleLinkDocument = gql`
    query GoogleLink($linkId: ID!) {
  googleLink(id: $linkId) {
    me {
      email
    }
  }
}
    `;

/**
 * __useGoogleLinkQuery__
 *
 * To run a query within a React component, call `useGoogleLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleLinkQuery({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useGoogleLinkQuery(baseOptions: Apollo.QueryHookOptions<GoogleLinkQuery, GoogleLinkQueryVariables> & ({ variables: GoogleLinkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleLinkQuery, GoogleLinkQueryVariables>(GoogleLinkDocument, options);
      }
export function useGoogleLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleLinkQuery, GoogleLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleLinkQuery, GoogleLinkQueryVariables>(GoogleLinkDocument, options);
        }
export function useGoogleLinkSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GoogleLinkQuery, GoogleLinkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GoogleLinkQuery, GoogleLinkQueryVariables>(GoogleLinkDocument, options);
        }
export type GoogleLinkQueryHookResult = ReturnType<typeof useGoogleLinkQuery>;
export type GoogleLinkLazyQueryHookResult = ReturnType<typeof useGoogleLinkLazyQuery>;
export type GoogleLinkSuspenseQueryHookResult = ReturnType<typeof useGoogleLinkSuspenseQuery>;
export type GoogleLinkQueryResult = Apollo.QueryResult<GoogleLinkQuery, GoogleLinkQueryVariables>;
export function refetchGoogleLinkQuery(variables: GoogleLinkQueryVariables) {
      return { query: GoogleLinkDocument, variables: variables }
    }
export const MicrosoftLinkDocument = gql`
    query MicrosoftLink($linkId: ID) {
  microsoftLink(id: $linkId) {
    me {
      id
      givenName
      surname
      mail
      mobilePhone
    }
  }
}
    `;

/**
 * __useMicrosoftLinkQuery__
 *
 * To run a query within a React component, call `useMicrosoftLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicrosoftLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicrosoftLinkQuery({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useMicrosoftLinkQuery(baseOptions?: Apollo.QueryHookOptions<MicrosoftLinkQuery, MicrosoftLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicrosoftLinkQuery, MicrosoftLinkQueryVariables>(MicrosoftLinkDocument, options);
      }
export function useMicrosoftLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicrosoftLinkQuery, MicrosoftLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicrosoftLinkQuery, MicrosoftLinkQueryVariables>(MicrosoftLinkDocument, options);
        }
export function useMicrosoftLinkSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MicrosoftLinkQuery, MicrosoftLinkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MicrosoftLinkQuery, MicrosoftLinkQueryVariables>(MicrosoftLinkDocument, options);
        }
export type MicrosoftLinkQueryHookResult = ReturnType<typeof useMicrosoftLinkQuery>;
export type MicrosoftLinkLazyQueryHookResult = ReturnType<typeof useMicrosoftLinkLazyQuery>;
export type MicrosoftLinkSuspenseQueryHookResult = ReturnType<typeof useMicrosoftLinkSuspenseQuery>;
export type MicrosoftLinkQueryResult = Apollo.QueryResult<MicrosoftLinkQuery, MicrosoftLinkQueryVariables>;
export function refetchMicrosoftLinkQuery(variables?: MicrosoftLinkQueryVariables) {
      return { query: MicrosoftLinkDocument, variables: variables }
    }
export const RedeemOAuthCodeDocument = gql`
    mutation RedeemOAuthCode($code: String!, $linkType: SsoLinkType!) {
  redeemOauthCode(code: $code, linkType: $linkType)
}
    `;
export type RedeemOAuthCodeMutationFn = Apollo.MutationFunction<RedeemOAuthCodeMutation, RedeemOAuthCodeMutationVariables>;

/**
 * __useRedeemOAuthCodeMutation__
 *
 * To run a mutation, you first call `useRedeemOAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemOAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemOAuthCodeMutation, { data, loading, error }] = useRedeemOAuthCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      linkType: // value for 'linkType'
 *   },
 * });
 */
export function useRedeemOAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<RedeemOAuthCodeMutation, RedeemOAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemOAuthCodeMutation, RedeemOAuthCodeMutationVariables>(RedeemOAuthCodeDocument, options);
      }
export type RedeemOAuthCodeMutationHookResult = ReturnType<typeof useRedeemOAuthCodeMutation>;
export type RedeemOAuthCodeMutationResult = Apollo.MutationResult<RedeemOAuthCodeMutation>;
export type RedeemOAuthCodeMutationOptions = Apollo.BaseMutationOptions<RedeemOAuthCodeMutation, RedeemOAuthCodeMutationVariables>;
export const SsoSignInDocument = gql`
    mutation SsoSignIn($linkId: ID!, $linkType: SsoLinkType!) {
  ssoSignIn(linkId: $linkId, linkType: $linkType)
}
    `;
export type SsoSignInMutationFn = Apollo.MutationFunction<SsoSignInMutation, SsoSignInMutationVariables>;

/**
 * __useSsoSignInMutation__
 *
 * To run a mutation, you first call `useSsoSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSsoSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ssoSignInMutation, { data, loading, error }] = useSsoSignInMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *      linkType: // value for 'linkType'
 *   },
 * });
 */
export function useSsoSignInMutation(baseOptions?: Apollo.MutationHookOptions<SsoSignInMutation, SsoSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SsoSignInMutation, SsoSignInMutationVariables>(SsoSignInDocument, options);
      }
export type SsoSignInMutationHookResult = ReturnType<typeof useSsoSignInMutation>;
export type SsoSignInMutationResult = Apollo.MutationResult<SsoSignInMutation>;
export type SsoSignInMutationOptions = Apollo.BaseMutationOptions<SsoSignInMutation, SsoSignInMutationVariables>;
export const ExistingUserDocument = gql`
    query ExistingUser($email: String) {
  user(email: $email) {
    id
    claimed
  }
}
    `;

/**
 * __useExistingUserQuery__
 *
 * To run a query within a React component, call `useExistingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useExistingUserQuery(baseOptions?: Apollo.QueryHookOptions<ExistingUserQuery, ExistingUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistingUserQuery, ExistingUserQueryVariables>(ExistingUserDocument, options);
      }
export function useExistingUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistingUserQuery, ExistingUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistingUserQuery, ExistingUserQueryVariables>(ExistingUserDocument, options);
        }
export function useExistingUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExistingUserQuery, ExistingUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExistingUserQuery, ExistingUserQueryVariables>(ExistingUserDocument, options);
        }
export type ExistingUserQueryHookResult = ReturnType<typeof useExistingUserQuery>;
export type ExistingUserLazyQueryHookResult = ReturnType<typeof useExistingUserLazyQuery>;
export type ExistingUserSuspenseQueryHookResult = ReturnType<typeof useExistingUserSuspenseQuery>;
export type ExistingUserQueryResult = Apollo.QueryResult<ExistingUserQuery, ExistingUserQueryVariables>;
export function refetchExistingUserQuery(variables?: ExistingUserQueryVariables) {
      return { query: ExistingUserDocument, variables: variables }
    }
export const PasswordSignUpDocument = gql`
    mutation PasswordSignUp($app: App!, $membershipId: String!, $companyName: String!, $name: String!, $email: String!, $phoneNumber: String!, $password: String!, $passwordConfirmation: String!, $emailOptIn: Boolean!, $code: String!) {
  signUp(
    app: $app
    membershipId: $membershipId
    companyName: $companyName
    name: $name
    email: $email
    phoneNumber: $phoneNumber
    password: $password
    passwordConfirmation: $passwordConfirmation
    emailOptIn: $emailOptIn
    code: $code
  ) {
    ... on SignUpOk {
      userId
    }
    ... on SignUpError {
      membershipId
      companyName
      name
      email
      phoneNumber
      password
      passwordConfirmation
      emailOptIn
    }
  }
}
    `;
export type PasswordSignUpMutationFn = Apollo.MutationFunction<PasswordSignUpMutation, PasswordSignUpMutationVariables>;

/**
 * __usePasswordSignUpMutation__
 *
 * To run a mutation, you first call `usePasswordSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordSignUpMutation, { data, loading, error }] = usePasswordSignUpMutation({
 *   variables: {
 *      app: // value for 'app'
 *      membershipId: // value for 'membershipId'
 *      companyName: // value for 'companyName'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      emailOptIn: // value for 'emailOptIn'
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePasswordSignUpMutation(baseOptions?: Apollo.MutationHookOptions<PasswordSignUpMutation, PasswordSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordSignUpMutation, PasswordSignUpMutationVariables>(PasswordSignUpDocument, options);
      }
export type PasswordSignUpMutationHookResult = ReturnType<typeof usePasswordSignUpMutation>;
export type PasswordSignUpMutationResult = Apollo.MutationResult<PasswordSignUpMutation>;
export type PasswordSignUpMutationOptions = Apollo.BaseMutationOptions<PasswordSignUpMutation, PasswordSignUpMutationVariables>;
export const SsoSignUpDocument = gql`
    mutation SsoSignUp($app: App!, $linkId: ID!, $linkType: SsoLinkType!, $membershipId: String!, $companyName: String!, $name: String!, $email: String!, $phoneNumber: String!, $emailOptIn: Boolean!, $code: String!) {
  ssoSignUp(
    app: $app
    linkId: $linkId
    linkType: $linkType
    membershipId: $membershipId
    companyName: $companyName
    name: $name
    email: $email
    phoneNumber: $phoneNumber
    emailOptIn: $emailOptIn
    code: $code
  ) {
    ... on SignUpOk {
      userId
    }
    ... on SignUpError {
      membershipId
      companyName
      name
      email
      phoneNumber
      password
      passwordConfirmation
      emailOptIn
    }
  }
}
    `;
export type SsoSignUpMutationFn = Apollo.MutationFunction<SsoSignUpMutation, SsoSignUpMutationVariables>;

/**
 * __useSsoSignUpMutation__
 *
 * To run a mutation, you first call `useSsoSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSsoSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ssoSignUpMutation, { data, loading, error }] = useSsoSignUpMutation({
 *   variables: {
 *      app: // value for 'app'
 *      linkId: // value for 'linkId'
 *      linkType: // value for 'linkType'
 *      membershipId: // value for 'membershipId'
 *      companyName: // value for 'companyName'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      emailOptIn: // value for 'emailOptIn'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSsoSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SsoSignUpMutation, SsoSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SsoSignUpMutation, SsoSignUpMutationVariables>(SsoSignUpDocument, options);
      }
export type SsoSignUpMutationHookResult = ReturnType<typeof useSsoSignUpMutation>;
export type SsoSignUpMutationResult = Apollo.MutationResult<SsoSignUpMutation>;
export type SsoSignUpMutationOptions = Apollo.BaseMutationOptions<SsoSignUpMutation, SsoSignUpMutationVariables>;
export const UnclaimedUserSignUpAttemptedDocument = gql`
    mutation UnclaimedUserSignUpAttempted($userId: ID!) {
  unclaimedUserSignUpAttempted(userId: $userId)
}
    `;
export type UnclaimedUserSignUpAttemptedMutationFn = Apollo.MutationFunction<UnclaimedUserSignUpAttemptedMutation, UnclaimedUserSignUpAttemptedMutationVariables>;

/**
 * __useUnclaimedUserSignUpAttemptedMutation__
 *
 * To run a mutation, you first call `useUnclaimedUserSignUpAttemptedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnclaimedUserSignUpAttemptedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unclaimedUserSignUpAttemptedMutation, { data, loading, error }] = useUnclaimedUserSignUpAttemptedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnclaimedUserSignUpAttemptedMutation(baseOptions?: Apollo.MutationHookOptions<UnclaimedUserSignUpAttemptedMutation, UnclaimedUserSignUpAttemptedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnclaimedUserSignUpAttemptedMutation, UnclaimedUserSignUpAttemptedMutationVariables>(UnclaimedUserSignUpAttemptedDocument, options);
      }
export type UnclaimedUserSignUpAttemptedMutationHookResult = ReturnType<typeof useUnclaimedUserSignUpAttemptedMutation>;
export type UnclaimedUserSignUpAttemptedMutationResult = Apollo.MutationResult<UnclaimedUserSignUpAttemptedMutation>;
export type UnclaimedUserSignUpAttemptedMutationOptions = Apollo.BaseMutationOptions<UnclaimedUserSignUpAttemptedMutation, UnclaimedUserSignUpAttemptedMutationVariables>;