import Card from './Card'

const UnexpectedError = () => (
  <div className="mt-20 grid justify-items-center">
    <Card title="Whoops, something went wrong.">
      <Card.Section>For help, contact support@gearflow.com</Card.Section>
    </Card>
  </div>
)

export default UnexpectedError
